export const useCurrencyFormat = (number, devise = '€', decimals = 2) => {
  const num = parseFloat(number);
  if (isNaN(num)) return number;

  const isPercentage = devise === '%';
  const roundedValue = useRound(num, decimals);
  const formattedValue = isPercentage
    ? roundedValue.toString()
    : roundedValue.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${formattedValue} ${devise}`;
};
